import { BaseClass } from './base'
import defaultConfig from '@/config/default.config'

export class BillClass extends BaseClass {
  gets (params) {
    return this.get(this.bill, params)
  }

  getPersonals (params) {
    return this.get(this.bill + '/PersonalBills', { ...params, StoreId: defaultConfig.store_id })
  }
}
